import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../UserContext';
import { SelectButton } from 'primereact/selectbutton';
import { AppBox } from '../components/AppBox';
import { InputText } from 'primereact/inputtext';

export const Dashboard = () => {
	const [data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [tagListPrimary, setTagListPrimary] = useState([]);
	const [tagListSecondary, setTagListSecondary] = useState([]);
	const [selectedTagsPrimary, setSelectedTagsPrimary] = useState("");
	const [selectedTagsSecondary, setSelectedTagsSecondary] = useState("");
	const [search,setSearch] = useState();
	const user = useContext(UserContext);

	useEffect(() => {
		user.apiCall(`portalcards/${process.env.REACT_APP_ENV_LABEL}`)
			.then((res) => {
				setData(res.data?.cards);
				setFilteredData(res.data?.cards);
				console.log(res)
				const tagList = res.data?.tagList;
				let tlp = [];
				let tls = [];				
				for(let t of tagList){
					//if(t.isPrimary){
						tlp.push(t)
					// }
					// else{
					// 	tls.push(t)
					// }
				}
				tlp = tlp.map((val) => val.tagName);
				tls = tls.map((val) => val.tagName)
				setTagListPrimary(tlp)
				setTagListSecondary(tls)
			});

		// Check layout-main and erase padding
		const holder = document.getElementsByClassName("layout-main");
		holder[0].classList.add("erase-padding");

		return () => {
			const holder = document.getElementsByClassName("layout-main");
			holder[0].classList.remove("erase-padding");
		}
	},[]);

	useEffect(() => {
		let searchFiltered = [];
		let filtered = [];
		if(search){
			searchFiltered= data.filter((val) => {
				return (
					val.appTitle?.toLowerCase().includes(search.toLowerCase()) ||
					val.appDescShort?.toLowerCase().includes(search.toLowerCase())
				);
			});
		}
		else{
			searchFiltered = data;
		}
		if (selectedTagsPrimary || selectedTagsSecondary) {
			searchFiltered.forEach((val) => {
				if (val.tags.includes(selectedTagsPrimary) || val.tags.includes(selectedTagsSecondary)) {
					filtered.push(val);
				}
			});
		} else {
			filtered = searchFiltered;
		}
		console.log(searchFiltered)
		setFilteredData(filtered);
	}, [search, selectedTagsPrimary, selectedTagsSecondary, data]);
	
	return (
		<div id="PortalDashboard">
			{filteredData.length > 0 && (
				<h1>{data.length} Apps Available</h1>
			)}
			<div style={{display:'inline-block'}}>
			<SelectButton options={tagListPrimary}
				value={selectedTagsPrimary}
				onChange={(e) => setSelectedTagsPrimary(e.target.value)}
				style={{marginRight:"200px"}}
			/>

			<SelectButton className="tagsSecondary" options={tagListSecondary}
				value={selectedTagsSecondary}
				onChange={(e) => setSelectedTagsSecondary(e.target.value)}
				
			/>
			</div>
			<div style={{display:'inline-block',verticalAlign:'top', float:'right', position:'absolute', top:'130px', right:'2%'}}>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText placeholder="Search" onChange={(e) => setSearch(e.target.value)}/>
				</span>
			</div>
			<div style={{marginTop:'15px'}}className="box-container">
				{filteredData.map((app, appIdx) => (
					<AppBox key={appIdx} {...app} />
				))}
			</div>
		</div>
	);
}